export const nFormatter = (num: number, digits: number) => {
    const suffixes = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const formatted = suffixes
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return formatted
        ? (num / formatted.value).toFixed(digits).replace(rx, "$1") + formatted.symbol
        : "0";
}