import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { setCookie, hasCookie } from 'cookies-next';
import { countryCookieName } from 'helpers/geoCountries';
import { getGeoInfo } from 'services/modalCountryService';
import { useDispatch, useSelector } from 'react-redux';
import { setGeoData, IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import ModalCountryMain from 'components/blocks/Modal/ModalCountry/ModalCountryMain';
import ModalCountrySelect from 'components/blocks/Modal/ModalCountry/ModalCountrySelect';
import { useDefaultCountryId } from 'helpers/hooks/defaultCountryId';

const ModalCountry: React.FC = () => {
  const { t } = useTranslation('modal');
  const dispatch = useDispatch();
  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const defaultCountryId = useDefaultCountryId();

  const { setModalType } = useContext(AppContext);

  const [currentCountry, setCurrentCountry] = useState(
    'modal.countries.defaultCountry'
  );

  const currentCountryChange = (modalState: string) => {
    setCurrentCountry(modalState);
  };

  const changeCountry = async countryId => {
    dispatch(setGeoData(countryId));
    setCurrentCountry(t(`geoCountries.${countryId}`));
    setCookie(countryCookieName, countryId);
  };

  useEffect(() => {
    const geoCode = async () => {
      const response = await getGeoInfo();

      if (response) {
        changeCountry(response);
      }

      setModalType(MODAL.country.select);

      if (!hasCookie(countryCookieName)) {
        setCookie(countryCookieName, geoDataStored);
      }
    };

    dispatch(setGeoData(defaultCountryId));

    if (hasCookie(countryCookieName)) {
      return;
    }

    geoCode();
  }, [hasCookie(countryCookieName)]);

  return (
    <>
      <ModalCountryMain currentCountry={currentCountry} />
      <ModalCountrySelect setCurrentCountry={currentCountryChange} />
    </>
  );
};

export default ModalCountry;
