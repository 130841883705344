import React, { useRef, useMemo, useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useIsMobile } from 'helpers/hooks/isMobile';
import { menuLinksDefault, menuLinksMainPage } from 'helpers/menuLinks';
import { MenuItem } from 'interfaces/Common/Menu';
import { useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import { IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';
import { IOrderStore } from 'stores/orderStore';
import { useScrollingDirection } from 'helpers/hooks/scrollingDirection';
import { importantAppLinks } from 'helpers/socialLinks';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import LiveIcon from 'components/blocks/Icons/LiveIcon';
import LanguageToggle from './LanguageToggle';
import Link from 'next/link';
import ScrollIntoView from 'react-scroll-into-view';
import ModalExit from 'components/blocks/Modal/ModalExit';
import TechSupport from 'components/blocks/TechSupport';
import ModalCountry from 'components/blocks/Modal/ModalCountry';

import styles from './Header.module.scss';
import { nFormatter } from 'helpers/hooks/nFormatter';

const Header: React.FC = () => {
  const { t } = useTranslation(['header', 'modal']);
  const header = useRef(null);
  const accountList = useRef(null);
  const { setModalType, setModalAuthAfterSuccess } = useContext(AppContext);

  const [burgerActive, burgerToggle] = useState(false);
  const [accountListActive, setAccountListActive] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isNavigationHidden, setNavigationHidden] = useState(false);

  useScrollingDirection({
    onScrollDown() {
      setNavigationHidden(true);
    },
    onScrollUp() {
      setNavigationHidden(false);
    },
    threshold: 125,
  });

  const router = useRouter();
  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const orderStored = useSelector<RootState, IOrderStore>(state => state.order);

  const orderDataStored = orderStored.orderData;

  const isLanding = useMemo(
    () =>
      router.pathname === '/' ||
      router.pathname === '/organizers' ||
      router.pathname === '/var',
    [router.pathname]
  );
  const isMobile = useIsMobile(1280);

  const menuLinks =
    router.pathname === '/' ? menuLinksMainPage : menuLinksDefault;
  const isCart = router.pathname.indexOf('/cart') === 0;

  const mobileMenuToggle = (isOpen: boolean) => {
    if (!isMobile) {
      return;
    }

    burgerToggle(isOpen);

    if (isOpen) {
      document.querySelector('body').classList.add('lock');

      return;
    }

    document.querySelector('body').classList.remove('lock');
  };

  useClickAway(accountList, () => {
    if (accountListActive) {
      setAccountListActive(false);
    }
  });

  const menuItem = (menuItem: MenuItem) => (
    <>
      {menuItem.link.includes('/') ? (
        <Link href={menuItem.link}>
          <a className={cn(styles.header__menuLink, 'link')}>
            {t(`header.menu.${menuItem.id}`)}
          </a>
        </Link>
      ) : (
        <>
          {isLanding ? (
            <ScrollIntoView selector={menuItem.link}>
              <Link href='/'>
                <a
                  onClick={event => {
                    event.preventDefault();
                    mobileMenuToggle(false);
                  }}
                  className={cn(styles.header__menuLink, 'link')}
                >
                  {t(`header.menu.${menuItem.id}`)}
                </a>
              </Link>
            </ScrollIntoView>
          ) : (
            <Link href={`/${menuItem.link}`}>
              <a className={cn(styles.header__menuLink, 'link')}>
                {t(`header.menu.${menuItem.id}`)}
              </a>
            </Link>
          )}
        </>
      )}
    </>
  );

  const geoElem = (
    <div
      className={styles.header__geo}
      onClick={() => {
        setModalType(MODAL.country.select);
      }}
    >
      <svg
        className={styles.header__geoIcon}
        role='img'
        focusable='false'
        dangerouslySetInnerHTML={{
          __html: '<use xlink:href="/images/icons/sprite-header.svg#geo" />',
        }}
      />
      {t(`geoCountries.${userStored.geoData}`, { ns: 'modal' })}
    </div>
  );

  const accountButtonHandler = () => {
    if (userStored.isAuthorized) {
      setAccountListActive(!accountListActive);
      return;
    }

    setModalAuthAfterSuccess('');
    setModalType(MODAL.auth.signUp);
  };

  useEffect(() => {
    if (header.current) {
      setHeaderHeight(header.current.offsetHeight);
    }
  }, [header.current?.offsetHeight]);

  return (
    <>
      <header
        id='header'
        ref={header}
        className={cn(styles.header, {
          [styles.headerMobile]: isMobile,
          [styles.headerCart]: isCart,
          [styles.headerFixed]: isNavigationHidden,
        })}
      >
        <div className='container'>
          <div className={styles.header__inner}>
            <div className={styles.header__top}>
              <div className={styles.header__showOnlyDesktop}>{geoElem}</div>
              <div className={styles.header__showOnlyDesktop}>
                <LanguageToggle isMobile={false} theme='white' />
              </div>
            </div>
            <div className={styles.header__main}>
              <Link href={'/'}>
                <img
                  src='/images/header-logo-idChess.svg'
                  alt='idChess'
                  className={styles.header__logo}
                />
              </Link>
              <nav
                className={cn(styles.header__menu, {
                  [styles.active]: burgerActive,
                })}
              >
                <div>
                  <ul className={styles.header__menuList}>
                    {menuLinks.map((item: MenuItem) => (
                      <li
                        key={item.id}
                        className={styles.header__menuItem}
                        onClick={() => mobileMenuToggle(false)}
                      >
                        {menuItem(item)}
                      </li>
                    ))}
                    <li
                      className={cn(
                        styles.header__menuItem,
                        styles.header__menuItemGreen
                      )}
                      onClick={() => {
                        setModalAuthAfterSuccess(
                          userStored.isAuthorized ? '' : MODAL.auth.activate
                        );
                        setModalType(
                          userStored.isAuthorized
                            ? MODAL.auth.activate
                            : MODAL.auth.signUp
                        );
                      }}
                    >
                      <span className={cn(styles.header__menuLink, 'link')}>
                        {t('header.activeCode')}
                      </span>
                    </li>
                    {!isMobile && (
                      <li className={styles.header__menuItem}>
                        <Link href={importantAppLinks.app}>
                          <a
                            className={cn('link', styles.header__tournaments)}
                            target='_blank'
                          >
                            <LiveIcon />
                            {t('header.tournaments')}
                          </a>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className={styles.header__showOnlyMobile}>
                  <hr />
                  {geoElem}
                  <div className={styles.header__langMobile}>
                    <LanguageToggle isMobile={true} />
                  </div>
                  <hr />
                  <TechSupport />
                </div>
              </nav>
              <div className={styles.header__rightIcons}>
                <div
                  ref={accountList}
                  className={cn(styles.header__account, {
                    [styles.open]: accountListActive,
                  })}
                >
                  {userStored.isAuthorized ? (
                    <div
                      className={cn(
                        styles.header__accountButton,
                        styles.authorized
                      )}
                      onClick={accountButtonHandler}
                    >
                      <svg
                        className={styles.header__accountIcon}
                        role='img'
                        focusable='false'
                        dangerouslySetInnerHTML={{
                          __html:
                            '<use xlink:href="/images/icons/sprite-header.svg#person" />',
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={styles.header__accountText}
                      onClick={accountButtonHandler}
                    >
                      {t('header.account.enter')}
                    </div>
                  )}
                  <div className={styles.header__accountList}>
                    {userStored.isAuthorized ? (
                      <>
                        <Link href='/account'>
                          <a className={styles.header__accountListItem}>
                            {t('header.account.name')}
                          </a>
                        </Link>
                        <div
                          className={styles.header__accountListItem}
                          onClick={() => setModalType(MODAL.exit)}
                        >
                          {t('header.account.exit')}
                        </div>
                      </>
                    ) : (
                      <div
                        className={styles.header__accountListItem}
                        onClick={() => {
                          setModalAuthAfterSuccess('');
                          setModalType(MODAL.auth.signIn);
                        }}
                      >
                        {t('header.account.enter')}
                      </div>
                    )}
                  </div>
                </div>
                <Link href='/cart'>
                  <a className={styles.header__cart}>
                    <svg
                      className={styles.header__cartIcon}
                      role='img'
                      focusable='false'
                      dangerouslySetInnerHTML={{
                        __html:
                          '<use xlink:href="/images/icons/sprite-header.svg#cart" />',
                      }}
                    />
                    {orderDataStored?.quantity > 0 && (
                      <div className={styles.header__cartCount}>
                        {nFormatter(orderDataStored.quantity, 1)}
                      </div>
                    )}
                  </a>
                </Link>
                <div
                  className={cn(
                    styles.header__menuCall,
                    styles.header__showOnlyMobile,
                    {
                      [styles.active]: burgerActive,
                    }
                  )}
                  onClick={() => mobileMenuToggle(!burgerActive)}
                >
                  <span />
                </div>
              </div>
            </div>
            {isMobile && (
              <div className={styles.header__bottom}>
                <Link href={importantAppLinks.app}>
                  <a
                    className={cn('link', styles.header__tournaments)}
                    target='_blank'
                  >
                    <LiveIcon />
                    {t('header.tournaments')}
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </header>
      <div
        style={{ height: `${headerHeight}px` }}
        className={styles.header__showOnlyMobile}
      />
      <ModalExit />
      <ModalCountry />
    </>
  );
};

export default Header;
