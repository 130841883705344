import { getCookie, hasCookie } from 'cookies-next';
import { countryCookieName } from 'helpers/geoCountries';
import { useTranslation } from 'next-i18next';
import { getGeoInfo } from 'services/modalCountryService';

export const useDefaultCountryId = () => {
  const { t } = useTranslation(['modal']);
  return hasCookie(countryCookieName)
    ? getCookie(countryCookieName)
    : t('modal.countries.defaultCountryId');
};

export const currentGeo = async props =>
  props.req.cookies?.idchess_country_id ?? (await getGeoInfo(props.locale));
