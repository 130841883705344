import axios from 'axios';

export const getGeoInfo = async (locale?: string): Promise<string> => {
  const DEFAULT_GEO = locale === 'ru' ? 'RU' : 'US';
  try {
    // пока оставим только ip
    /*if (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return geoCountries[userTimeZone];
    }*/

    const response = await axios
      .get('https://ipapi.co/json/')
      .then(response => {
        if (typeof response?.data?.country_code === 'string') {
          return response.data.country_code;
        }

        return false;
      });

    return response ?? DEFAULT_GEO;
  } catch (e) {
    console.error(e?.response);
    return DEFAULT_GEO;
  }
};
